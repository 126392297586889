<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>Data Aktarım</b-card-title>
        <b-dropdown
          text="Aktarım Şablonları"
          variant="danger"
          size="sm"
          dropleft
        >
          <b-dropdown-item
            target="_blank"
            :href="baseUrl + '/media/templates/sifir-satis.xlsx'"
          >
            Sıfır Satış
          </b-dropdown-item>
          <b-dropdown-item
            target="_blank"
            :href="baseUrl + '/media/templates/ikinci-el-satis.xlsx'"
          >
            İkinci El
          </b-dropdown-item>
          <b-dropdown-item
            target="_blank"
            :href="baseUrl + '/media/templates/filo-kiralama.xlsx'"
          >
            Filo Kiralama
          </b-dropdown-item>
          <b-dropdown-item
            target="_blank"
            :href="baseUrl + '/media/templates/gayrimenkul-yatirim.xlsx'"
          >
            Gayrimenkul Yatırım
          </b-dropdown-item>
          <b-dropdown-item @click="usersModal = true">
            Güncel Danışman Listesi
          </b-dropdown-item>
        </b-dropdown>
      </b-card-header>
      <b-card-body v-if="saveStatus.status !== null">
        <b-alert
          :variant="saveStatus.status? 'success' : 'danger'"
          show
        >
          <div class="alert-body text-center py-2">
            <FeatherIcon
              :icon="saveStatus.status? 'CheckCircleIcon' : 'XCircleIcon'"
              size="24"
            />
            <p class="font-medium-4">
              {{ saveStatus.message }}
            </p>
            <p class="mt-1">
              Aktarım oranı {{ saveStatus.imported }}/{{ saveStatus.posted }} adet veri aktarılmıştır.
            </p>
            <b-button
              :variant="saveStatus.status? 'success' : 'danger'"
              class="mt-1"
              size="sm"
              :to="'/marketing/batch-interviews'"
            >
              Aktarım Sonucu
            </b-button>
          </div>
        </b-alert>
      </b-card-body>
      <template v-else>
        <b-card-body>
          <b-row>
            <b-col>
              <b-form-group
                label="Departman"
                label-for="id_com_interview_type"
              >
                <v-select
                  id="id_com_interview_type"
                  v-model="id_com_interview_type"
                  placeholder="Departman"
                  :options="interviewTypes"
                  :reduce="item => item.id"
                  label="title"
                />
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                label="Konu"
                label-for="id_com_interview_subject"
              >
                <v-select
                  id="id_com_interview_subject"
                  v-model="id_com_interview_subject"
                  placeholder="Konu"
                  :options="interviewSubjects"
                  :reduce="item => item.id"
                  label="title"
                  :disabled="interviewSubjects.length === 0"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <sifir-satis
            v-if="id_com_interview_type === '1'"
            :fields="tableFields"
            :data-list="dataList"
            :interview-type="id_com_interview_type"
            :interview-subject="id_com_interview_subject"
          />
          <ikinci-el
            v-if="id_com_interview_type === '3'"
            :fields="tableFields"
            :data-list="dataList"
            :interview-type="id_com_interview_type"
            :interview-subject="id_com_interview_subject"
          />
          <filo-kiralama
            v-if="id_com_interview_type === '5'"
            :fields="tableFields"
            :data-list="dataList"
            :interview-type="id_com_interview_type"
            :interview-subject="id_com_interview_subject"
          />
          <gayrimenkul-yatirim
            v-if="id_com_interview_type === '6'"
            :fields="tableFields"
            :data-list="dataList"
            :interview-type="id_com_interview_type"
            :interview-subject="id_com_interview_subject"
          />
          <hr>
          <b-form-group
            v-if="id_com_interview_type"
            class="mt-2"
            label="Aktarım Verisi"
            label-for="content"
          >
            <b-form-textarea
              v-model="content"
              placeholder="Aktarım yapmak istediğiniz kayıtları liste halinde ekleyin..."
              rows="10"
            />
          </b-form-group>
          <b-alert
            class="mt-2"
            show
          >
            <div class="alert-body font-small-2">
              <p>Örnek aktarım dosyalarında bulunan excel DATA dışındaki excel çalışma alanlarında Temas Tipi, Marka, Model gibi listeler bulunmakta, bu listelerden ilgili verileri kopyalarsanız daha iyi eşleme yapılacaktır.</p>
              <p>Veri listelerin ilgili satırındaki almak istediğiniz satırın ID sutunlarındaki numaraları kopyalayıp alırsanız tam eşleme yapacaktır. (Örnek: Citroen için 1 Honda için 2 gibi)</p>
              <p>Gönderilen boş satırlar veritabanına aktarılmaz.</p>
            </div>
          </b-alert>
        </b-card-body>
        <b-card-footer class="text-center">
          <b-button
            variant="danger"
            size="lg"
            :disabled="submitStatus"
            @click="saveData"
          >
            <FeatherIcon
              icon="UploadIcon"
              size="24"
              class="mr-1"
            />
            AKTARIMI BAŞLAT
          </b-button>
        </b-card-footer>
      </template>
    </b-card>
    <b-modal
      v-model="usersModal"
      centered
      size="lg"
      title="Güncel Kullanıcı Listesi"
      hide-footer
    >
      <user-list />
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardFooter, BFormGroup, BAlert, BFormTextarea, BCardHeader, BCardTitle, BCardBody, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import UserList from '@/views/Marketing/BatchInterviews/Index/UserList.vue'
import SifirSatis from '@/views/Marketing/BatchInterviews/Preview/SifirSatis.vue'
import IkinciEl from '@/views/Marketing/BatchInterviews/Preview/IkinciEl.vue'
import FiloKiralama from '@/views/Marketing/BatchInterviews/Preview/FiloKiralama.vue'
import GayrimenkulYatirim from '@/views/Marketing/BatchInterviews/Preview/GayrimenkulYatirim.vue'

export default {
  name: 'Import',
  components: {
    BRow,
    BCol,
    BCard,
    BCardFooter,
    BFormGroup,
    BAlert,
    BFormTextarea,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BDropdown,
    BDropdownItem,
    BButton,
    vSelect,
    UserList,
    SifirSatis,
    IkinciEl,
    FiloKiralama,
    GayrimenkulYatirim,
  },
  data() {
    return {
      baseUrl: this.$store.state.app.baseURL,
      id_com_interview_type: null,
      id_com_interview_subject: null,
      usersModal: false,
      content: null,
      dataList: [],
      tableFields: [],
      submitStatus: false,
    }
  },
  computed: {
    users() {
      return this.$store.getters['users/getUsers']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    models() {
      return this.$store.getters['models/getModels']
    },
    meets() {
      return this.$store.getters['meets/getMeets']
    },
    realtyProjects() {
      return this.$store.getters['realtyProjects/getRealty_sale_projects']
    },
    interviewTypes() {
      return this.$store.getters['interviewTypes/getInterview_types']
    },
    interviewSubjects() {
      return this.$store.getters['interviewSubjects/getInterview_subjects']
    },
    importList() {
      return this.$store.getters['marketingBatchInterviews/importList']
    },
    saveStatus() {
      return this.$store.getters['marketingBatchInterviews/dataSaveStatus']
    },
  },
  watch: {
    content(val) {
      if (val) {
        this.dataList = []
        this.tableFields = []
        this.$store.commit('marketingBatchInterviews/SET_IMPORT_LIST', [])
        const dataList = this.content.split('\n')
        dataList.forEach((e, index) => {
          const cols = e.split('\t')
          if (index > 0) {
            if (this.id_com_interview_type === '1') {
              this.dataList.push(cols)
              this.importList.push({
                id_com_user: this.getUserID(cols[0]),
                id_com_brand: this.getBrandID(cols[1]),
                id_com_model: this.getModelID(cols[2]),
                idate: cols[3],
                id_com_meet: this.getMeetID(cols[4]),
                customer_name: cols[5],
                customer_phone: cols[6],
                customer_email: cols[7],
                content: cols[8],
                ads_id: cols[9],
                id_com_interview_type: this.id_com_interview_type,
                id_com_interview_subject: this.id_com_interview_subject,
              })
            }
            if (this.id_com_interview_type === '3') {
              this.dataList.push(cols)
              this.importList.push({
                id_com_user: this.getUserID(cols[0]),
                inallar2_advert: cols[1],
                idate: cols[2],
                id_com_meet: this.getMeetID(cols[3]),
                customer_name: cols[4],
                customer_phone: cols[5],
                customer_email: cols[6],
                content: cols[7],
                ads_id: cols[8],
                id_com_interview_type: this.id_com_interview_type,
                id_com_interview_subject: this.id_com_interview_subject,
              })
            }
            if (this.id_com_interview_type === '5') {
              this.dataList.push(cols)
              this.importList.push({
                id_com_user: this.getUserID(cols[0]),
                idate: cols[1],
                id_com_meet: this.getMeetID(cols[2]),
                customer_name: cols[3],
                customer_phone: cols[4],
                customer_email: cols[5],
                content: cols[6],
                ads_id: cols[7],
                id_com_interview_type: this.id_com_interview_type,
                id_com_interview_subject: this.id_com_interview_subject,
              })
            }
            if (this.id_com_interview_type === '6') {
              this.dataList.push(cols)
              this.importList.push({
                id_com_user: this.getUserID(cols[0]),
                id_com_realty_project: this.getRealtyProjectID(cols[1]),
                idate: cols[2],
                id_com_meet: this.getMeetID(cols[3]),
                customer_name: cols[4],
                customer_phone: cols[5],
                customer_email: cols[6],
                content: cols[7],
                ads_id: cols[8],
                id_com_interview_type: this.id_com_interview_type,
                id_com_interview_subject: this.id_com_interview_subject,
              })
            }
          } else {
            this.tableFields = cols
          }
        })
      }
    },
    id_com_interview_type(val) {
      if (val) {
        this.id_com_interview_subject = null
        this.tableFields = []
        this.$store.commit('marketingBatchInterviews/SET_IMPORT_LIST', [])
        this.content = null
        this.getInterviewSubjects(val)
      }
    },
  },
  created() {
    this.$store.commit('marketingBatchInterviews/SET_DATA_SAVE', {
      imported: null, posted: null, message: null, status: null,
    })
    this.getUserList()
    this.getMeetList()
    this.getInterviewTypes()
  },
  methods: {
    getUserID(selected) {
      let data = null
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(selected)) {
        data = this.users.find(({ title }) => title === selected)
      } else {
        data = this.users.find(({ id }) => id === selected)
      }
      if (data) {
        data = data.id
      }
      return data
    },
    getBrandID(selected) {
      let data = null
      // eslint-disable-next-line no-restricted-globals
      selected = selected.trim()
      if (isNaN(selected)) {
        data = this.brands.find(({ title }) => title === selected)
      } else {
        data = this.brands.find(({ id }) => id === selected)
      }
      if (data) {
        data = data.id
      }
      return data
    },
    getModelID(selected) {
      let data = null
      // eslint-disable-next-line no-restricted-globals
      selected = selected.trim()
      if (isNaN(selected)) {
        data = this.models.find(({ title }) => title === selected)
      } else {
        data = this.models.find(({ id }) => id === selected)
      }
      if (data) {
        data = data.id
      }
      return data
    },
    getMeetID(selected) {
      let data = null
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(selected)) {
        data = this.meets.find(({ title }) => title === selected)
      } else {
        data = this.meets.find(({ id }) => id === selected)
      }
      if (data) {
        data = data.id
      }
      return data
    },
    getRealtyProjectID(selected) {
      let data = null
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(selected)) {
        data = this.realtyProjects.find(({ title }) => title === selected)
      } else {
        data = this.realtyProjects.find(({ id }) => id === selected)
      }
      if (data) {
        data = data.id
      }
      return data
    },
    saveData() {
      this.submitStatus = true
      this.$store.dispatch('marketingBatchInterviews/saveData', this.importList)
        .then(response => {
          if (response) {
            this.submitStatus = false
          }
        })
    },
    getUserList() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id AS id',
          'com_user.name AS title',
          'com_brand.name AS brand',
          'com_department.title AS department',
        ],
        where: {
          'com_user.status': 1,
          'com_user.webform': 1,
          'com_user.id_com_brand !=': 10,
        },
        order_by: ['com_user.id_com_brand', 'ASC'],
      })
    },
    getMeetList() {
      this.$store.dispatch('meets/meetsList', {
        select: [
          'com_meet.id AS id',
          'com_meet.title AS title',
        ],
        where_in: [
          ['com_meet.id', [3, 5, 10, 18, 34, 35, 43, 44, 45, 47, 53]],
        ],
      })
    },
    getInterviewTypes() {
      this.$store.dispatch('interviewTypes/interview_typesList', {
        select: [
          'com_interview_type.id AS id',
          'com_interview_type.title AS title',
        ],
        where: {
          'com_interview_type.batch': 1,
        },
      })
    },
    getInterviewSubjects(id) {
      this.$store.dispatch('interviewSubjects/interview_subjectsList', {
        select: [
          'com_interview_subject.id AS id',
          'com_interview_subject.title AS title',
        ],
        where: {
          'com_interview_subject.id_com_interview_type': id,
        },
      })
    },
  },
}
</script>
